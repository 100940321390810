<template>
    <transition name="fade">
        <!--div v-if="showing" :class="getOverlay( overlay )"-->
        <div  v-if="showing" class="loadingScreenWrapper">
            <div class="loadingScreen">
                <div class="logoContainer">

                    <svg t="1582580254768" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1403" width="64" height="64"><path d="M255.5 960c-40.5 0-65.9-2-67.9-2.2-10.2-0.8-19.4-6.5-24.7-15.2s-6.2-19.5-2.3-28.9l126.7-307.3c-45.2-13.8-111.4-44.4-153.8-110.1-40.1-62.2-40.8-114.5-34.3-147.5 8.1-41.5 29-80.5 62-116.2 26-28 59.5-54 99.8-77.2 71.8-41.4 142.8-61.5 162.1-65.3 42.5-8.4 152.1-25.2 252.3-0.7 50.5 12.3 155.4 38 227 136.2 58.2 79.8 63.1 165.8 65.3 202.5 2.9 49.5-2.5 98.3-15.8 145.1-10.9 38.3-27.4 75.5-48.8 110.6-37.8 61.9-80 99.3-93.8 111.6-50.9 45.2-115.7 81.8-192.4 108.8-60.4 21.3-128.5 36.8-202.3 46C351.8 958 296 960 255.5 960z m-18.3-64.2c39.2 0.6 100.8-0.5 170.5-9.3 69.1-8.7 132.4-23.1 188.4-42.9 68.6-24.2 126-56.6 170.6-96.1 48.8-43.3 73.5-83.8 81.7-97.1 41.2-67.4 59.8-141 55.3-218.6-1.9-33.7-6-103.9-53.1-168.5-57.8-79.2-144.2-100.3-190.5-111.7-90.3-22.1-194.9-4.6-224.7 1.3-20 3.9-83.6 24-142.5 58-34.7 20-63.2 42-84.8 65.3-24.8 26.8-40.3 55.4-46.1 85-6.1 31.2 2.4 65 25.3 100.5 33.8 52.5 89.7 75.6 124.5 85.4L429 262.9c4.9-12 16.6-19.8 29.6-19.8 2 0 50.7 0.2 99.9 17.3 30.8 10.7 55.7 25.8 74.1 44.9 24.5 25.4 36.9 57 36.9 93.9 0 49.2-18.3 95.3-52.9 133.2C581.1 571 529.2 600 466.4 616c-44.7 11.4-90.7 7.6-115.5 4L237.2 895.8z m139-337.3c20.2 1.8 48.1 2.2 74.4-4.5 50.7-12.9 91.7-35.3 118.6-64.9 23.7-26 36.2-57.1 36.2-90.1 0-13.5 0-54.7-67.9-78.3-21.2-7.4-42.9-10.8-58.1-12.4L376.2 558.5z" p-id="1404" fill="#FF5353"></path><path d="M862.25224692 913.71014322m-41.42522469 0a41.42522469 41.42522469 0 1 0 82.85044939 0 41.42522469 41.42522469 0 1 0-82.85044939 0Z" p-id="1405" fill="#FF5353"></path></svg>

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'OverlayLoading',
        data() {
            return {
                showing: false
            }
        },
        methods: {
            show() {
                this.showing = true;
            },
            hide() {
                this.showing = false;
            }
        }
    };
</script>
