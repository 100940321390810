// overlay-loading.js  并不是最终的文件，它只是对 overlay-loading.vue 添加了一个方法 newInstance。
import OverlayLoading from './overlay-loading.vue';
import Vue from 'vue';

OverlayLoading.newInstance = properties => {
  const props = properties || {};

  const Instance = new Vue({
        data: props,
        render (h) {
            return h(OverlayLoading, {
                props: props
            });
        }
    });

    const component = Instance.$mount();
    document.body.appendChild(component.$el);

    const overlayLoading = Instance.$children[0];

    return {
        component: overlayLoading,
        show() {
            overlayLoading.show();
        },
        hide() {
            overlayLoading.hide();
        },
        destroy (element) {
            overlayLoading.removeAll();
            setTimeout(function() {
                document.body.removeChild(document.getElementsByClassName(element)[0]);
            }, 500);
        }
    }
};

export default OverlayLoading;
