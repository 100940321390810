// toast.js  并不是最终的文件，它只是对 alert.vue 添加了一个方法 newInstance。
import Toast from './toast.vue';
import Vue from 'vue';

Toast.newInstance = properties => {
  const props = properties || {};

  const Instance = new Vue({
        data: props,
        render (h) {
            return h(Toast, {
                props: props
            });
        }
    });

    const component = Instance.$mount();
    document.body.appendChild(component.$el);

    const toast = Instance.$children[0];

    return {
        component: toast,
        add (noticeProps) {
            //--- removeAll 则toast允许在界面上叠加
            toast.removeAll();
            toast.add(noticeProps);
        },
        remove (name) {
            toast.remove(name);
        },
        destroy (element) {
            toast.removeAll();
            setTimeout(function() {
                document.body.removeChild(document.getElementsByClassName(element)[0]);
            }, 500);
        }
    }
};

export default Toast;