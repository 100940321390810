//--- 基础控件
import Button from './foundation/button';
import Input from './foundation/input';
import Modal from './foundation/modal';
import {Row, Col} from './foundation/grid';

//--- 页面布局
//import Layout from './foundation/layout';
//import Header from './foundation/header';
//import Content from './foundation/content';
//import Footer from './foundation/footer';

//--- 本项目
import BlankInput from './studio/blank-input';
import Toast from './studio/toast';
import OverlayLoading from './studio/overlay-loading';
import { swiper, swiperSlide } from 'vue-awesome-swiper'

const components = {
    Col,
    Row,
    Button,
    Input,
    Modal,

    //Layout: Layout,
    //Header: Header,
    //Content: Content,
    //Footer: Footer,

    swiper,
    swiperSlide,
    BlankInput,
    Toast,
    OverlayLoading,
};

const iview = {
    ...components,
    iCol: Col,
    iButton: Button,
    iInput: Input,

};

const install = function(Vue, opts = {}) {
    if (install.installed) return;
    //locale.use(opts.locale);
    //locale.i18n(opts.i18n);

    // 在vue中注册组件
    Object.keys(iview).forEach(key => {
        Vue.component(key, iview[key]);
    });

    Vue.prototype.$IVIEW = {
        size: opts.size || '',
        transfer: 'transfer' in opts ? opts.transfer : ''
    };

    //全局插件
    Vue.prototype.$Modal = Modal;
    Vue.prototype.$Toast = Toast;
    Vue.prototype.$Loading = OverlayLoading

};

// auto install
if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue);
}

const API = {
    version: process.env.VERSION, // eslint-disable-line no-undef
    //locale: locale.use,
    //i18n: locale.i18n,
    install,
    //Circle,
    //Switch,
    ...components
};
/*
API.lang = (code) => {
    const langObject = window['iview/locale'].default;
    if (code === langObject.i.locale) locale.use(langObject);
    else console.log(`The ${code} language pack is not loaded.`); // eslint-disable-line no-console
};
*/
module.exports.default = module.exports = API;   // eslint-disable-line no-undef
//统一改成 es6 的写法
//export default API
