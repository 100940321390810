<template>
    <input class="blank-input" 
    type="text"
    ref="input"

    :name="name"
    :value="value"
    :placeholder="placeholder"

    @compositionstart="handleComposition"
    @compositionupdate="handleComposition"
    @compositionend="handleComposition"
    @input="handleInput"
    @change="handleChange">
</template>

<script>
    ///const prefixCls = 'ivu-toast';

    export default {
        name: 'BlankInput',
        props: {
            label: { // 文字
                type: String,
            },
            name: {
                type: String
            },
            value: {
                type: [String, Number],
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                currentValue: this.value,
                isOnComposition: false,
            }
        },
        computed: {},

        methods: {
            handleComposition(event) {
                if (event.type === 'compositionstart') {
                    this.isOnComposition = true;
                }
                if (event.type === 'compositionend') {
                    this.isOnComposition = false;
                    this.handleInput(event);
                }
            },
            handleInput (event) {
                if (this.isOnComposition) return;

                let value = event.target.value;
                if (this.number && value !== '') value = Number.isNaN(Number(value)) ? value : Number(value);
                //console.log(value)
                this.$emit('input', value);
                this.setCurrentValue(value);
                this.$emit('on-change', event);
            },
            handleChange (event) {
                this.$emit('on-input-change', event);
            },
            setCurrentValue (value) {
                if (value === this.currentValue) return;
                this.currentValue = value;
            },


        },
        watch: {
            value (val) {
                this.setCurrentValue(val);
            }
        },
        //mounted () {}
    };
</script>
