//import OverlayLoading from './overlay-loading.vue';
//export default OverlayLoading;
import OverlayLoading from './overlay-loading.js';

//--- 全局单例模式
let overlayLoadingInstance;
function getOverlayLoadingInstance () {
    overlayLoadingInstance = overlayLoadingInstance || OverlayLoading.newInstance();
    return overlayLoadingInstance;
}

function overlayLoading(operation='show') {
    let instance = getOverlayLoadingInstance();
    if('show' === operation) {
    	instance.show();
    } else {
    	instance.hide();
    }
    
}

export default {
    name: 'OverlayLoading',
    show() {
    	overlayLoading('show')
    },
    hide() {
    	overlayLoading('hide')
    },
    //--- 挂载在body下，路由无用，手动销毁
    destroy() {
        let instance = getOverlayLoadingInstance();
        overlayLoadingInstance = null;
        // document.getElementsByClassName
        instance.destroy('loadingScreenWrapper');
    }
}