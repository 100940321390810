const API = {
	// api基础路径
	base: 'https://beastush.sweetrouble.com',
	//base:'http://115.28.43.187:8080',
    //base:'http://127.0.0.1:8080',
	//static:'/v1/file',
    helper: '/v1/helper',
};


export default API;