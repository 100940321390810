// alert.js
import Toast from './toast.js';
//--- 图标

const iconPrefixCls = 'iconfont';
let toastInstance;

//--- 默认设置
const defaults = {
    duration: 1.5
};

//--- 图标
const iconTypes = {
    'success': 'icon-motion',
    'error': 'icon-unmotion',
    'loading': 'icon-loading'
};

function getToastInstance () {
    toastInstance = toastInstance || Toast.newInstance();
    return toastInstance;
}

function toast(content='', duration=defaults.duration, type) {
    const iconType = iconTypes[type];
    const loadCls = type === 'loading' ? ' ivu-load-loop' : '';

    let instance = getToastInstance();
    //<img :src="${iconType}" class="${loadCls}"/>
    instance.add({
        content: content,
        icon: `
            <i class="${iconPrefixCls} ${iconType} ${loadCls}"></i>
        `,
        duration: duration
    });

    //instance.destroy('toast');
}

export default {
    name: 'Toast',
    success (options) {
        return this.toast('success', options);
    },
    error (options) {
        return this.toast('error', options);
    },
    loading (options) {
        return this.toast('loading', options);
    },

    toast(type, options) {
        return toast(options.content, options.duration, type);
    },

    //--- 挂载在body下，路由无用，手动销毁
    destroy() {
        let instance = getToastInstance();
        toastInstance = null;
        // document.getElementsByClassName
        instance.destroy('toast');
    }
}

