<template>
  <div class="toast" v-if="notices.length">
    <div class="toast-main" v-for="item in notices" :key="item.name">
        <div class="toast-icon" ref="icon" v-html="item.icon"></div>
        <div class="toast-content">{{ item.content }}</div>
    </div>
  </div>
</template>

<script>
let seed = 0;
const now = Date.now();
function getUuid() {
    return 'toast_' + now + '_' + (seed++);
}

export default {
    data () {
        return {
            notices: []
        }
    },

    methods: {
        add (notice) {
            const name = getUuid();
            //console.log(name)

            let _notice = Object.assign({
              name: name
            }, notice);

            this.notices.push(_notice);

            // 定时移除，单位：秒
            const duration = notice.duration;
            
            setTimeout(() => {
                this.remove(name);
                //document.body.removeChild(document.getElementsByClassName('toast')[0]);
            }, duration * 1000);
            
        },
        remove (name) {
            const notices = this.notices;

            for (let i = 0; i < notices.length; i++) {
                if (notices[i].name === name) {
                    this.notices.splice(i, 1);
                    break;
                }
            }
        },

        removeAll () {
            this.notices = [];
        },

    }
}
</script>
